// Generated with OneLightJekyll applied to Atom's One Light theme

.highlight,
pre.highlight {
  background: #f9f9f9;
  color: #383942;
}
.highlight pre {
  background: #f9f9f9;
}
.highlight .hll {
  background: #f9f9f9;
}
.highlight .c {
  color: #9fa0a6;
  font-style: italic;
}
.highlight .err {
  color: #fff;
  background-color: #e05151;
}
.highlight .k {
  color: #a625a4;
}
.highlight .l {
  color: #50a04f;
}
.highlight .n {
  color: #383942;
}
.highlight .o {
  color: #383942;
}
.highlight .p {
  color: #383942;
}
.highlight .cm {
  color: #9fa0a6;
  font-style: italic;
}
.highlight .cp {
  color: #9fa0a6;
  font-style: italic;
}
.highlight .c1 {
  color: #9fa0a6;
  font-style: italic;
}
.highlight .cs {
  color: #9fa0a6;
  font-style: italic;
}
.highlight .ge {
  font-style: italic;
}
.highlight .gs {
  font-weight: 700;
}
.highlight .kc {
  color: #a625a4;
}
.highlight .kd {
  color: #a625a4;
}
.highlight .kn {
  color: #a625a4;
}
.highlight .kp {
  color: #a625a4;
}
.highlight .kr {
  color: #a625a4;
}
.highlight .kt {
  color: #a625a4;
}
.highlight .ld {
  color: #50a04f;
}
.highlight .m {
  color: #b66a00;
}
.highlight .s {
  color: #50a04f;
}
.highlight .na {
  color: #b66a00;
}
.highlight .nb {
  color: #ca7601;
}
.highlight .nc {
  color: #ca7601;
}
.highlight .no {
  color: #ca7601;
}
.highlight .nd {
  color: #ca7601;
}
.highlight .ni {
  color: #ca7601;
}
.highlight .ne {
  color: #ca7601;
}
.highlight .nf {
  color: #383942;
}
.highlight .nl {
  color: #ca7601;
}
.highlight .nn {
  color: #383942;
}
.highlight .nx {
  color: #383942;
}
.highlight .py {
  color: #ca7601;
}
.highlight .nt {
  color: #e35549;
}
.highlight .nv {
  color: #ca7601;
}
.highlight .ow {
  font-weight: 700;
}
.highlight .w {
  color: #f8f8f2;
}
.highlight .mf {
  color: #b66a00;
}
.highlight .mh {
  color: #b66a00;
}
.highlight .mi {
  color: #b66a00;
}
.highlight .mo {
  color: #b66a00;
}
.highlight .sb {
  color: #50a04f;
}
.highlight .sc {
  color: #50a04f;
}
.highlight .sd {
  color: #50a04f;
}
.highlight .s2 {
  color: #50a04f;
}
.highlight .se {
  color: #50a04f;
}
.highlight .sh {
  color: #50a04f;
}
.highlight .si {
  color: #50a04f;
}
.highlight .sx {
  color: #50a04f;
}
.highlight .sr {
  color: #0083bb;
}
.highlight .s1 {
  color: #50a04f;
}
.highlight .ss {
  color: #0083bb;
}
.highlight .bp {
  color: #ca7601;
}
.highlight .vc {
  color: #ca7601;
}
.highlight .vg {
  color: #ca7601;
}
.highlight .vi {
  color: #e35549;
}
.highlight .il {
  color: #b66a00;
}
.highlight .gu {
  color: #75715e;
}
.highlight .gd {
  color: #e05151;
}
.highlight .gi {
  color: #43d089;
}
.highlight ::selection {
  background-color: #fff;
}
.highlight .language-json .w + .s2 {
  color: #e35549;
}
.highlight .language-json .kc {
  color: #0083bb;
}
